export const strict = false
import { BASE_API, Get, Post } from '../api'

const getForm = () => {
  let form = null
  try {
    form = JSON.parse(localStorage.getItem('form'))
  } catch (e) {
  }
  return form
}

export default {
  namespaced: true,
  state: {
    loading: false,
    error: null,
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload
    },
    setError(state, payload) {
      state.error = payload
    },
    setForm(state, payload) {
      localStorage.setItem('form', JSON.stringify(payload))
    }
  },
  actions: {
    async register({commit}, payload) {
      commit('setForm', payload)
      commit('setLoading', true)
      const res = await Post(`users/register`, payload)
      commit('setError', res.status)
      const snackbar = {
        snack: true,
        message: res.message,
        status: res.status
      }
      commit('snackbar/setSnack', snackbar, {root: true})
      commit('setLoading', false)
    },
    async resendEmail({commit, state}) {
      if (!getForm()) {
        return
      }
      commit('setLoading', true)
      const res = await Post(`users/resend_email`, getForm())
      commit('setError', res.status)
      const snackbar = {
        snack: true,
        message: res.message,
        status: res.status
      }
      commit('snackbar/setSnack', snackbar, {root: true})
      commit('setLoading', false)
    }
  }
}